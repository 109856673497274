import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import featureImage from "../../static/images/tic.png";
import thumbnailEvent from "../../static/images/step.svg"
import thumbnailBoard from "../../static/images/hbc.svg"
import thumbnailNews from "../../static/images/salbaek.svg"
import thumbnailTeams from "../../static/images/estrap.svg"
import thumbnailStaff from "../../static/images/web.svg"

const IndexPage = () => (
    <Layout>
        <SEO title="Never Home. No Problem."/>

        <div className={"page-header home"}>
            <h1>Never Home. No Problem.</h1>
            <p>Got question? Contact us for more information</p>
            <img alt={"Dashboard"} src={featureImage}/>
        </div>

        <div className={"container"}>
            <div className={"features"}>
                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Event"} src={thumbnailEvent}/>
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Sal-Tech Easy Packaging</h2>
                                <p>Sal-Tech Easy Packaging offers a wide range of solutions for simple and reliable packaging of your products. <a href="https://sal-tech.com" target="_blank">Read More.</a></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Hallbrook Components</h2>
                                <p>Let the staff write down on the board freely,<br/>Serves all customers, dealers and distributors with a range of spare parts, components, features and selections of packaging machines and automation solutions in general. <a href="https://hallbrookcomponents.com/" target="_blank">Read More.</a></p>
                            </div>
                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Board"} src={thumbnailBoard}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"News"} src={thumbnailNews}/>
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Salbaek Technology Group</h2>
                                <p>With more than 20 years in the industry we can help you to set up an optimized solution that fits your needs based on product and demands. <a href="https://sal-tech.dk/" target="_blank">Read More.</a></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Easy Strapping Machine</h2>
                                <p>Offers you an extensive series of packaging materials/components, tools, and machines. Always ensuring that there is a unique collection of items at an economical value for your needs. <a href="https://easystrapping.com/en/" target="_blank">Read More.</a></p>
                            </div>
                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Team"} src={thumbnailTeams}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Users"} src={thumbnailStaff}/>
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Web2u4Free</h2>
                                <p>The concept is simple, what you see is what you pay, the rest is handled by methods that can be organized so they don’t add cost to the production, distribution and trade process. <a href="https://web2u43.com/" target="_blank">Read More.</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className={"call-to-action"}>
            <div className={"container"}>
                <div className={"call-to-action__content"}>
                    <h2>Get in touch </h2>
                    <p>Feel free to contact us and we will get back to you as soon as it possible.</p>
                </div>

                <div className={"button"}>
                    <a href="mailto:support@sal-tech.com?Subject=Ticbox%20Rocks%20Inquiries" target="_top" target={"_blank"}>Get Started</a>
                </div>
            </div>
        </div>
    </Layout>
)

export default IndexPage
